<template>
  <div>
    <div class="template-name-header">
      <span class="organization-name">{{organizationName}}</span><span v-if="templateName" class="template-name"> - {{templateName}}</span>
    </div>
    <div class="contilt-df-wrapper" style="margin-bottom: -10px">
      <div class="content-body">
        <div class="row">
          <div
            :class="(hoverConfig || debugId || pin ? 'col-8' : 'col-10') + ' contilt-easetans'"
          >
            <div
              class="vue-form-wizard wizard-vertical mb-3 md vertical"
              style="overflow: hidden"
            >
              <div class="wizard-navigation">
                <ul role="tablist" class="wizard-nav wizard-nav-pills">

                  <li

                    v-for="(item, nodeIndex) in possibleNodeTypes"
                    class="drag-drawflow active"
                    draggable
                    @dragstart="drag($event)"
             
                    :data-node="item.nodeType"
                    :key="item.nodetype"
                  >
                    <div v-if="!possibleNodeTypes[nodeIndex -1] || possibleNodeTypes[nodeIndex -1].categoryLabel != possibleNodeTypes[nodeIndex].categoryLabel"  class="category-header text-truncate">
                      <span>{{item.categoryLabel}}</span>
                    </div>
                    <a class=""                     v-b-tooltip.hover
                    :title="item.nodeTypeTitle">
                      <div
                      
                        id="step-AccountDetails0"
                        aria-controls="AccountDetails0"
                        aria-disabled="true"
                        aria-selected="true"
                        class="wizard-icon-circle md checked"
                        style="border-color: rgb(115, 103, 240)"
                      >
                        <div
                          class="wizard-icon-container"
                          style="background-color: rgb(115, 103, 240)"
                        >
                  
                          <i class="wizard-icon feather" :class="item.icon || 'icon-triangle'"></i>
                        </div>
                      </div>
                      <span
                      
                        class="stepTitle component-title active"
                        style="color: rgb(115, 103, 240)"
                        
                      >
                        {{ item.nodeTypeTitle }}
                      </span>
                    </a>
                  </li>

   

                 
                </ul>

                <div class="wizard-tab-content">
                  <div>
                    <div class="df-wrapper wrapper">
                      <div
                        id="drawflow"
                        @drop="drop($event)"
                        @dragover="allowDrop($event)"
                      >
                        <div
                          class="btn-export"
                          onclick="Swal.fire({ title: 'Export',
                        html: '<pre><code>'+JSON.stringify(editor.export(), null,4)+'</code></pre>'
                        })"
                        >
                          Export
                        </div>
                        <b-button
                        class="btn-clear"
                          variant="danger"
                          @click="deleteSelectedTemplates([templateId])"
                        >
                          Delete
                        </b-button>
                        <!--<div class="btn-lock">
                          <i
                            id="lock"
                            class="fas fa-lock"
                            onclick="editor.editor_mode='fixed'; changeMode('lock');"
                          ></i>
                          <i
                            id="unlock"
                            class="fas fa-lock-open"
                            onclick="editor.editor_mode='edit'; changeMode('unlock');"
                            style="display: none"
                          ></i>
                        </div>-->
                        <div class="bar-zoom">
                          <i
                            class="fas fa-search-minus"
                            @click="editor.zoom_out()"
                          >
                            <svg
                              class="svg-inline--fa fa-search-minus fa-w-16"
                              onclick="editor.zoom_out()"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="search-minus"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M304 192v32c0 6.6-5.4 12-12 12H124c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z"
                              ></path>
                            </svg>
                          </i>

                          <i class="fas fa-search" @click="editor.zoom_reset()">
                            <svg
                              class="svg-inline--fa fa-search fa-w-16"
                              onclick="editor.zoom_reset()"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="search"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                              ></path>
                            </svg>
                          </i>
                          <i
                            class="fas fa-search-plus"
                            @click="editor.zoom_in()"
                          >
                            <svg
                              class="svg-inline--fa fa-search-plus fa-w-16"
                              onclick="editor.zoom_in()"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="search-plus"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z"
                              ></path>
                            </svg>
                          </i>
                        </div>
                        <div class="drawflow">
                          <div :key="node.id" v-for="node in nodes">
                            <node-component
                              :key="node.id"
                              :node="node"
                              :templateId="templateId"
                              :selectedNodeId="selectedNodeId"
                              @open-new-sub-template="addSubTemplate(templateId, $event, true)"
                              @connect-to-inputs="connectNodeToInputs({nodeId: selectedNodeId, inputNodes})"
                              @sub-template-data="exposeSubTemplateInputsOutputs"
                              @open-new-tab-template="openNewTabTemplate"
                              @on-created="onNodeComponentCreated"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="(hoverConfig || debugId || pin ? 'col-4' : 'col-2') + ' contilt-easetans'"
            @mouseover="hoverConfig = true"
            @mouseleave="hoverConfig = false"
          >
            <div class="card mb-4">
              <div class="card-body">
                <!-- Right Config Editor-->



                <div v-if="!selectedNodeId">
                  <card-rightside></card-rightside>
                </div>
                <div v-else>
                  <b-tabs :key="selectedNodeId" v-if="debugId">
                    <b-tab title="Inputs">
                      <DebugEditor :key="selectedNodeId" :node="selectedNode" targetContext="inputs" />
                    </b-tab>
                    <b-tab title="Outputs">
                      <DebugEditor :key="selectedNodeId" :node="selectedNode" targetContext="outputs" />
                    </b-tab>
                    <b-tab v-if="selectedNode.debugData && selectedNode.debugData.error" title="Error">
                      <DebugError :key="selectedNodeId" :error="selectedNode.debugData.error" />
                    </b-tab>
                    <b-tab title="Config">
                      <template-config-items
                        ref="template-config-items"
                       
                        :key="selectedNodeId"
                        @set-inputs-outputs="exposeSubTemplateInputsOutputs"
                        :configItems="selectedNode.configItems"
                        :selectedNode="selectedNode"
                      ></template-config-items>
                    </b-tab>
                  </b-tabs>
                  <template-config-items
                    ref="template-config-items"
                    :inputs="inputNodesNames"
                    @connect-to-inputs="connectNodeToInputs({nodeId: selectedNodeId, inputNodes})"
                    v-else
                    :key="selectedNodeId"
                     @pin="pin = !pin"
                     :pin="pin"
                    @set-inputs-outputs="exposeSubTemplateInputsOutputs"
                    :configItems="selectedNode.configItems"
                    :selectedNode="selectedNode"
                  ></template-config-items>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contilt-df-documentation">
      <b-row class="match-height">
        <b-col lg="4">
          <b-card title="Template Documentation">
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Template Name" label-for="documentName">
                    <b-form-input
                      id="documentName"
                      placeholder="Write a documentation for the input"
                      v-model="templateName"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- first name -->
                <b-col cols="12">
                  <b-form-group
                    label="Write a description for the functionality of this template"
                    label-for="v-first-name"
                  >
                    <b-form-textarea
                      id="textarea-auto-height"
                      placeholder=""
                      rows="4"
                      max-rows="10"
                      v-model="templateDocumentation"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Include free text -->
                <b-col cols="12">
                  <b-form-group>
                    <b-form-checkbox
               
             
                      v-model="applyFreeText">
                     Apply free text
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- first name -->
                <b-col cols="12">
                  <b-form-group
                    label="Select image for the template"
                    label-for="v-image-url"
                  >
                    <b-form-file
                      id="wildcard"
                      v-model="templateImageFile"
                      accept="image/*"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Select template type"
           
                  >
                    <v-select
                      v-model="templateTypes"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="value"
                      :options="possibleTemplateTypes"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Order"
           
                  >
                    <b-form-input
                      v-model="order"
                      type="number"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
           
                      label="value"
                      :options="possibleTemplateTypes"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
        <b-col lg="4">
          <b-card title="Input Documentations">
            <b-form @submit.prevent>
              <b-row
                v-for="inputNode in inputNodes"
                :key="inputNode.id + 'inputdoc'"
              >
                <!-- first name -->
                <b-col cols="12">
                  <b-form-group
                    :label="inputNode.configItems[0].value"
                  
                  >
                    <b-form-input
                      v-if="inputNode.configItemsByNames && inputNode.configItemsByNames['documentation']"
                     
                      placeholder="Write a documentation for the input"
                      v-model="inputNode.configItemsByNames['documentation'].value"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
        <b-col lg="4">
          <b-card title="Output Documentations">
            <b-form @submit.prevent>
              <b-row
                v-for="outputNode in outputNodes"
                :key="outputNode.id + 'outputdoc'"
              >
                <!-- first name -->
                <b-col cols="12" v-if="outputNode.configItemsByNames && outputNode.configItemsByNames['variableName']">
                  <b-form-group
                  :key="outputNode.id "
                    :label="outputNode.configItemsByNames['variableName'].value"
                   
                  >
                    <b-form-input
                      v-if="outputNode.configItemsByNames && outputNode.configItemsByNames['documentation']"
                      placeholder="Write a documentation for the output"
                      v-model="outputNode.configItemsByNames['documentation'].value"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="contilt-workflow-category-labels">
      <b-row class="match-height">
        <b-col lg="6">
          <b-card title="Workflow Categories">
            <v-select
              v-model="workflowCategories"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="value"
              :options="possibleCategoies"
            />
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card title="Workflow Labels">
            <v-select
              v-model="workflowLabels"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="value"
              :options="possibleLabels"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="contilt-df-save-buttons">
      <b-row class="match-height">
        <b-col lg="12">
          <b-card>
            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="!ioIsNamed"
              class="mb-1"
            >
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                Missing Input or Output Variable names, please name all the IO
                nodes.
              </div>
            </b-alert>

            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="!ioIsDocumented"
              class="mb-1"
            >
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                Documentation is missing for some Inputs or Outputs.
              </div>
            </b-alert>

            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="!templateDocumented"
              class="mb-1"
            >
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                Please fill the template name and documentation.
              </div>
            </b-alert>
            <div style="text-align: center">
              <b-button
                @click="saveTemplate(templateId)"
                variant="gradient-primary"
                v-if="templateDocumented && ioIsDocumented && ioIsNamed"
              >
                Save Template
              </b-button>
              <b-button
                variant="gradient-danger"
                v-if="!(templateDocumented && ioIsDocumented && ioIsNamed)"
              >
                Please Fix Errors to Save The Template
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import NodeComponent from "./NodeComponent.vue";
import CardRightside from "./CardRightside.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BCardText,
  BForm,
  BCard,
  BButton,
  BFormGroup,
  BAlert,
  BFormTags,
  BFormFile,
  BTab,
  BTabs,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import axios from "@axios";
import Selectables from "@core/selectables/selectables"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TemplateConfigItems from "./TemplateConfigItems.vue";
import { compressImageToBase64 } from "../compressImage";
import { v4 as uuidv4 } from 'uuid'
import { ref, onUnmounted } from '@vue/composition-api'
import contiltEditorStoreModule from "../contiltEditorStoreModule"
import store from '@/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import DebugEditor from './DebugEditor.vue';
import DebugError from './DebugError.vue';
export default {
  name: "TemplateEditor",
  components: {
    NodeComponent,
    DebugEditor,
    DebugError,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BCardText,
    BFormTextarea,
    CardRightside,
    BFormInput,
    BCard,
    BButton,
    BFormGroup,
    BAlert,
    BFormSelect,
    vSelect,
    BFormTags,
    BFormFile,
    TemplateConfigItems,
    TemplateConfigItems,
    BTab,
    BTabs
  },
  directives: {
    "height-fade": heightFade,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    nodes(){
      return store.getters[`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/nodes`]
    },
    editor(){
      return store.getters[`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/editor`]
    },
    templateId() {
      return this.$route.params.templateId;
    },
    debugId() {
      return this.$route.query.debugId;
    },
    parentNodeId() {
      return this.$route.query.parentNodeId;
    },
    templateSourceId() {
      return this.$route.query.sourceId;
    },
    organizationName() {
      return this.$route.query.organizationName;
    },
    nodesToPassIdFromQuery() {
      return this.$route.query.nodesToPassId;
    },
    templateTypesFromQuery() {
      return this.$route.query.templateTypes
    },
    nodeById() {
      let res = {};
      this.nodes.forEach((currNode) => {
        res[currNode.id] = currNode;
      });
      return res;
    },


  
    nodeTypeById() {
      return store.getters[`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/nodeTypeById`]
    },
    possibleNodeTypes() {
      const orders = ["Inputs", "Outputs", "Generators", "General"];
      const res = [];
      orders.forEach(x => {
        this.nodeTypeList.forEach(y => {
          if(y.archive){
            return;
          }
          if(!y.categoryLabel){
            y.categoryLabel = "General"
          }
          if(y.categoryLabel == x){
            res.push(y);
          }
        })
      })
      return res
    },
    nodeTypesById(){
      let res = {};
      this.nodeTypeList.forEach(x => {
        res[x.nodeType] = x;
      })
      return res;
    },
    inputNodes() {
      let res = [];
      this.nodes.forEach((currNode) => {
        if (currNode.nodeType == "inputNode" || currNode.nodeTypeObject.category == "Inputs") {
          res.push(currNode);
        }
      });
      return res;
    },
    inputNodesNames() {
      let res = [];
      this.inputNodes.forEach(x => {
        if(x.configItemsByNames["variableName"] && x.configItemsByNames["variableName"].value){
          res.push(x.configItemsByNames["variableName"].value)
        }
      })
      return res;
    },
    outputNodes() {
      let res = [];
      this.nodes.forEach((currNode) => {
        if (currNode.nodeTypeObject.category == "Outputs") {
          res.push(currNode);
        }
      });
      return res;
    },
    ioIsDocumented() {
      for (let i = 0; i < this.outputNodes.length; i++) {
        if (this.outputNodes[i].configItemsByNames["documentation"] && this.outputNodes[i].configItemsByNames["documentation"].value.trim().length < 1) {
          return false;
        }
      }
      for (let i = 0; i < this.inputNodes.length; i++) {
        if (this.inputNodes[i].configItemsByNames["documentation"] && this.inputNodes[i].configItemsByNames["documentation"].value.trim().length < 1) {
          return false;
        }
      }
      return true;
    },
    ioIsNamed() {
      for (let i = 0; i < this.outputNodes.length; i++) {
        if (this.outputNodes[i].configItemsByNames["variableName"] && this.outputNodes[i].configItemsByNames["variableName"].value.trim().length < 1) {
          return false;
        }
      }
      for (let i = 0; i < this.inputNodes.length; i++) {
        if (this.inputNodes[i].configItemsByNames["variableName"] && this.inputNodes[i].configItemsByNames["variableName"].value.trim().length < 1) {
          return false;
        }
      }
      return true;
    },
    templateDocumented() {
      return (
        this.templateName.trim().length > 0 &&
        this.templateDocumentation.trim().length > 0
      );
    },
    possibleLabels() {
      let labels = [];
      let self = this;
      this.workflowCategories.forEach(function (cat) {
        labels.push(...self.categoryLabels[cat.id]);
      });
      return [...new Set(labels)];
    },
  },
  methods: {
    ...mapActions("app-template-editor", {
            addNodeToDrawFlow : 'addNodeByNodeTypeName',
            connectNodeToInputs: 'connectNodeToInputs'
    }),
    deleteSelectedTemplates(templatesToDelete) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete the selected temlates?",
          {
            title: "Delete Templates",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .post('/templates/deleteTemplates', {templates: templatesToDelete})
              .then(response => this.$router.replace({name: "apps-template-editor-list"}))
              .catch(error => console.log(error))
          }
        });
    },
    openNewTabTemplate(templateId, query){
        let data = {
          name: "template-to-edit",
          query: this.$router.currentRoute.query,
          params: {}
        }
        if(templateId){
          data.params.templateId = templateId;
        }
        if(query){
          data.query = query;
        }
        let route = this.$router.resolve(data);
        window.open(route.href);
    },
     addSubTemplate(sourceTemplateId, templateTypes, forcSourceTemplate = false) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to add the input nodes the the sub template?`,
          {
            title: "Assign Templates",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          let nodesToPassId = null;
          if (value) {
            let inputNodes = JSON.parse(JSON.stringify(this.inputNodes));
            inputNodes.forEach(i => {
              if(i.outputs){
                Object.keys(i.outputs).forEach(o => {
                  i.outputs[o].connections = [];
                })
              }
              if(i.inputs){
                Object.keys(i.inputs).forEach(o => {
                  i.inputs[o].connections = [];
                })
              }
            })
            nodesToPassId = `nodesToPass${uuidv4()}`;
            localStorage.setItem(nodesToPassId, JSON.stringify(inputNodes));
          }
          if (forcSourceTemplate && !sourceTemplateId) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "XIcon",
                variant: "danger",
                text: `You need to save the current Template`,
              },
            });
            return;
          }
          const query = {...this.$route.query, sourceId: sourceTemplateId, templateTypes }
          if(nodesToPassId){
            query.nodesToPassId = nodesToPassId;
          }
          let route = this.$router.resolve({
            name: "apps-template-editor",
            query: query,
          });
          window.open(route.href);
        });
    },

    getEntityReaders(){
      let self = this;
      const entityReaders = {};
      this.nodes.filter(x=>x.nodeType == "entityReader").forEach(n => {
        if(n.configItemsByNames.templateId && n.configItemsByNames.templateId.value){
          const entityReaderInner = {};
          entityReaders[n.configItemsByNames.templateId.value] = entityReaderInner
          Object.keys(n.outputs).forEach(o => {
            if(n.outputs[o].connections && n.outputs[o].connections.length && n.outputs[o].connections[0].node && self.nodeById[n.outputs[o].connections[0].node]){
              const otherNode = self.nodeById[n.outputs[o].connections[0].node];
              if(otherNode.configItemsByNames["variableName"] && otherNode.configItemsByNames["variableName"].value){
                entityReaderInner[o] = otherNode.configItemsByNames["variableName"].value;
              }
            }
          })
        }
      });
      return entityReaders;

    },
    getInputsStructure() {
      return this.$store.getters[`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/getInputsStructure`]();
    },
    getOutputsStructure() {
      return this.$store.getters[`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/getOutputsStructure`]();
    },
    getDynamicOutputsStructure() {
      return this.$store.getters[`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/getDynamicOutputsStructure`]();
    },

    inputNodeToInputItem(inputNode) {
      return inputNode;
    },
    outputNodeToOutputItem(outputNode) {
      return outputNode;
    },






    loadTemplateById(templateId, nodesToImport) {
      let self = this;
      const callbacks = {
        nodeSelected: function(nodeId){
          self.selectedNodeId = nodeId;
          self.selectedNode = self.nodeById[nodeId];
        }
      }
      store
          .dispatch(`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/init`, {elementId: document.getElementById("drawflow"), nodeTypeList: this.nodeTypeList, callbacks})

      if (templateId) {
        axios
          .get(`/templates/${templateId}`)
          .then((response) => {
            const templateData = response.data;
            if (
              templateData &&
              templateData.categories &&
              templateData.categories.length > 0
            ) {
              const categoiesIdToVal = {};
              this.possibleCategoies.forEach(
                (x) => (categoiesIdToVal[x.id] = x)
              );
              this.workflowCategories = templateData.categories.map(
                (x) => categoiesIdToVal[x]
              );

              if (
                templateData &&
                templateData.labels &&
                templateData.labels.length > 0
              ) {
                const labelsIdToVal = {};
                templateData.categories.forEach((x) => {
                  this.categoryLabels[x].forEach((y) => {
                    labelsIdToVal[y.id] = y;
                  });
                });
                this.workflowLabels = templateData.labels.map(
                  (x) => labelsIdToVal[x]
                );
              }
            }

            this.templateName = templateData.name;
            this.applyFreeText = templateData.applyFreeText;
            this.order = templateData.order;
            this.templateDocumentation = templateData.documentation.description || "";
            this.templateData = templateData;
            this.templateTypes = templateData.templateTypes ? this.possibleTemplateTypes.filter(x => templateData.templateTypes.indexOf(x.id) > -1): [];
            
            store
              .dispatch(`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/import`, templateData.templateJson)

          

            // this.nodes.forEach((x) => {
          

            //   if (x.nodeType == "generator") {
            //     x.configItems.forEach((y) => {
            //       this.recalcio(y.value, x, y.exposeVars);
            //     });
            //   }
            // });
          })
          .catch((error) => console.log(error));
      } else {
  
     
    
          if(nodesToImport && nodesToImport.length > 0){
            store
              .dispatch(`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/import`, nodesToImport)
          }

        

        
        this.templateName = "";
        this.templateDocumentation = "";
      }
    },

    onNodeComponentCreated(nodeId) {
      this.editor.updateConnectionNodes(`node-${nodeId}`);
      var bar = new Selectables({
          elements: '.drawflow-node',
          selectedClass: 'selected',
          zone: '#drawflow',
          key: 'altKey',
          start: function(e) {
          
          }
        }); 
    },
    prepareTemplateData(
      name,
      templateTypes,
      inputNodes,
      outputNodes,
      workflowLabels,
      workflowCategories,
      templateDocumentation,
      templateJson,
      imageBase64
    ) {
      const templateJ = JSON.parse(JSON.stringify(templateJson))
      templateJ.forEach(x=>{
        delete x.nodeTypeObject;
        delete x.configItemsByNames;
      })
      const outputs = this.getOutputsStructure();
      const inputNodesSet = new Set(inputNodes.map((x) => x.id));
      const outputNodesSet = new Set(outputNodes.map((x) => x.id));
      const data = {
        name: name,
        order: this.order,
        templateTypes: templateTypes ? templateTypes.map((x) => x.id) : [],
        inputs: this.getInputsStructure().filter((x) =>
          inputNodesSet.has(x.nodeId)
        ),
        outputs: outputs,
        applyFreeText: this.applyFreeText,
        entityReaders: this.getEntityReaders(),
        labels: workflowLabels ? workflowLabels.map((x) => x.id) : [],
        categories: workflowCategories
          ? workflowCategories.map((x) => x.id)
          : [],
        documentation: {
          description: templateDocumentation,
          inputs: inputNodes.map((x) => {
            const varName = x.configItems.find(
              (y) => y.name === "variableName"
            );
            const doc = x.configItems.find((y) => y.name === "documentation");
            return {
              name: varName ? varName.value : "",
              description: doc ? doc.value : "",
            };
          }),
          outputs: outputNodes.map((x) => {
            const varName = x.configItems.find(
              (y) => y.name === "variableName"
            );
            const doc = x.configItems.find((y) => y.name === "documentation");
            return {
              name: varName ? varName.value : "",
              description: doc ? doc.value : "",
            };
          }),
        },
        templateJson: templateJ,
      };
      if (imageBase64) {
        data.imageBase64 = imageBase64;
      }
      return data;
    },

    async saveTemplate(templateId) {
      let imageBase64 = null;
      if (this.templateImageFile) {
        try {
          imageBase64 = await compressImageToBase64(
            this.templateImageFile,
            400,
            400,
            1.0
          );
        } catch (error) {
          console.error(error);
        }
      }

   
      const data = this.prepareTemplateData(
        this.templateName,
        this.templateTypes,
        this.inputNodes,
        this.outputNodes,
        this.workflowLabels,
        this.workflowCategories,
        this.templateDocumentation,
        this.nodes,
        imageBase64
      );

      const options = {
        headers: { "Content-Encoding": "gzip" },
      };
      if (templateId) {
        axios
          .patch(`/templates/${templateId}`, data, options)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Succesfully saved`,
                icon: "CheckIcon",
                variant: "success",
                text: `You have successfully saved the template [${data.name}]`,
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "XIcon",
                variant: "danger",
                text: `${error.message}`,
              },
            });
          });
      } else {
        if (this.templateSourceId) {
          data.sourceReference = this.templateSourceId;
          if (!data.templateTypes || data.templateTypes.length == 0) {
            data.templateTypes = ["SUB_TEMPLATE"];
          }
        }
        const organization = this.$route.query.organization || null;
        if (organization) {
          data.organization = organization;
        }
        axios
          .post("/templates", data, options)
          .then((res) => {
            this.$router
              .replace({
                name: "template-to-edit",
                query: this.$router.currentRoute.query,
                params: { templateId: res.data },
              })
              .then(() => {
                location.reload();
              });

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Succesfully added`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully added the template [${data.name}]`,
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "CheckIcon",
                variant: "danger",
                text: `${error.message}`,
              },
            });
          });
      }
    },
    getVars(text) {
      let self = this;
      let allVars = [];
      let currVars = self.parsePrompt(text).variables;
      currVars.forEach(function (toadd) {
        allVars.push(toadd);
      });

      return allVars;
    },
    recalcio(text, node, enabled, requiredVars, updateInputs = true, updateOutputs = false) {
      if(!requiredVars){
        requiredVars = [];
      }
      let self = this;
      if (!enabled) {
        return;
      }
      let allVars = this.getVars(text).concat(requiredVars);
      allVars = Array.from( new Set(allVars));
      if(updateInputs){
        self.editor.updateNodeInputs(node.id, allVars);
      }
      if(updateOutputs){
          self.editor.updateNodeOutputs(node.id, allVars);

      }
      
    },
    parsePrompt(text) {
      let vars = [
        ...new Set(
          [...text.matchAll(/\{\{([a-zA-Z][a-zA-Z0-9\-\_ ]*)\}\}/g)].map(
            function (arr) {
              return arr[1];
            }
          )
        ),
      ];
      return { variables: vars, text: text };
    },
    positionMobile(ev) {
      this.mobile_last_move = ev;
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    drag(ev) {
      if (!ev) {
        return;
      }
      if (ev.type === "touchstart") {
        this.mobile_item_selec = ev.target
          .closest(".drag-drawflow")
          .getAttribute("data-node");
      } else {
        ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
      }
    },
    drop(ev) {
      if (ev.type === "touchend") {
        var parentdrawflow = document
          .elementFromPoint(
            this.mobile_last_move.touches[0].clientX,
            this.mobile_last_move.touches[0].clientY
          )
          .closest("#drawflow");
        if (parentdrawflow != null) {
          this.addNodeToDrawFlow(
            {name: this.mobile_item_selec,
            pos_x: this.mobile_last_move.touches[0].clientX,
            pos_y: this.mobile_last_move.touches[0].clientY}
          );
        }
        this.mobile_item_selec = "";
      } else {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("node");
        this.addNodeToDrawFlow({name: data, pos_x: ev.clientX, pos_y: ev.clientY});
      }
    },
    
    exposeOutputObject(newConfig) {
      let self = this;
      let newOutputConfigs = this.$refs["template-config-items"].filterHiddenConfigs(newConfig);
      let outputs = [];
      newOutputConfigs.forEach(function (currConf) {
        if (
          currConf &&
          "name" in currConf &&
          currConf["name"] == "variableName"
        ) {
          outputs.push(currConf["value"]);
        }

        if (currConf && "type" in currConf && currConf["type"] == "itemlist") {
          currConf["items"].forEach(function (confsItm) {
            confsItm.forEach(function (innerCurrConf) {
              if (
                innerCurrConf &&
                "name" in innerCurrConf &&
                innerCurrConf["name"] == "variableName"
              ) {
                outputs.push(innerCurrConf["value"]);
              }
            });
          });
        }
      });

      let varSet = new Set(outputs);
      let isSame = false;
      if ("varSetCache" in this.selectedNode) {
        if (this.selectedNode["varSetCache"].size == varSet.size) {
          isSame = true;
          outputs.forEach(function (outputVar) {
            if (!self.selectedNode["varSetCache"].has(outputVar)) {
              isSame = false;
            }
          });
        }
      }

      if (!isSame) {
        this.selectedNode["varSetCache"] = varSet;
        self.editor.updateNodeInputs(this.selectedNode.id, outputs);
      }
    },
    getConfigAsString(config){
      let str = "";
      config.forEach(x => {
        if(x.value){
          str += x.value.toString();
        }else if(x.items){
          x.items.forEach(item => {
            str += this.getConfigAsString(item)
          })
        }
      })
      return str;
    },
    exposeSubTemplateInputsOutputs(inputs, outputs) {
      let self = this;
 
      const inputsVars = inputs.map((x) => x.name);
      const outputsVars = [];
      outputs.forEach((x) => {
        if (x.items) {
          x.items.forEach((y) => {
            outputsVars.push(`${x.name}__${y.name}`);
          });
        } else {
          outputsVars.push(x.name);
        }
      });  
      self.editor.updateNodeInputs(this.selectedNode.id, []);
      self.editor.updateNodeOutputs(this.selectedNode.id, []);
      setTimeout(() => {
        self.editor.updateNodeInputs(this.selectedNode.id, inputsVars);
        self.editor.updateNodeOutputs(this.selectedNode.id, outputsVars);
      }, 0);
      
    }
  },
  watch: {
    templateId: function (newVal) {
      this.loadTemplateById(newVal);
    },
    "selectedNode.configItems": {
      deep: true,
      handler(newConfig) {
        let self = this;

        if (!this.selectedNode) {
          return;
        }
        const elementMethods = newConfig.find(x=>x["methods"]);
        if(elementMethods && elementMethods.methods == "checkJSON" && elementMethods.value){
       
          try{
            JSON.parse(elementMethods.value);
            elementMethods.error = false;
          }
          catch(err){
            console.log(err);
            elementMethods.error = err.message;
          }
        }
        if (this.selectedNode.nodeTypeObject.category == "Outputs" && this.selectedNode.nodeType == "objectOutputNode") {
            this.exposeOutputObject(newConfig)
        }
        else if(this.selectedNode.nodeType == "extraction" && this.selectedNode.configItemsByNames["extractionScheme"].value && typeof this.selectedNode.configItemsByNames["extractionScheme"].value == "string"){
          this.selectedNode.configItemsByNames["extractionScheme"].value = JSON.parse(this.selectedNode.configItemsByNames["extractionScheme"].value)
          this.selectedNode.configItems[1].value = JSON.parse(this.selectedNode.configItems[1].value)
        }
        else if (this.selectedNode.nodeType == "SMBdownloader") {
          if(this.selectedNode.configItemsByNames["queryGroups"] && this.selectedNode.configItemsByNames["queryGroups"].items.length){
            const strVars = this.selectedNode.configItemsByNames["queryGroups"].items.map(x=>x[0].value.trim()).filter(x=>x).map(x=> `{{${x}}}`).join("\n")
            this.recalcio(strVars, this.selectedNode, true, null, false, true)

          }
            
        }
        else if (this.selectedNode.nodeType == "SMBextractAttrs") {
          if(this.selectedNode.configItemsByNames["attributesMap"] && this.selectedNode.configItemsByNames["attributesMap"].items.length){
            const strVars = this.selectedNode.configItemsByNames["attributesMap"].items.map(x=>x[0].value.trim()).filter(x=>x).map(x=> `{{${x}}}`).join("\n")
            this.recalcio(strVars, this.selectedNode, true, null, false, true)
            let requiredField = null;
            if(this.nodeTypesById[this.selectedNode.nodeType]){
              requiredField = this.nodeTypesById[this.selectedNode.nodeType].inputs;
            }
            this.recalcio(this.getConfigAsString(newConfig), this.selectedNode, true, requiredField, true, false)
          }
            
        }
        else if (this.selectedNode.nodeType == "customCode") {
          if(this.selectedNode.configItemsByNames["inputs"] && this.selectedNode.configItemsByNames["inputs"].items.length){
            let strVars = this.selectedNode.configItemsByNames["inputs"].items.map(x=>x[0].value.trim()).filter(x=>x).map(x=> `{{${x}}}`).join("\n") + "\n" + this.getConfigAsString(newConfig)
            this.recalcio(strVars, this.selectedNode, true, null, true, false)

         }
          if(this.selectedNode.configItemsByNames["outputs"] && this.selectedNode.configItemsByNames["outputs"].items.length){
            const strVars = this.selectedNode.configItemsByNames["outputs"].items.map(x=>x[0].value.trim()).filter(x=>x).map(x=> `{{${x}}}`).join("\n")
            this.recalcio(strVars, this.selectedNode, true, null, false, true)
          }
        }
        else if (
          this.selectedNode.nodeTypeObject.category == "Outputs" &&
          this.selectedNode.nodeType == "outputNode"
        ) {
          self.editor.updateNodeInputs(
            this.selectedNode.id,
            [newConfig[0].value],
            false
          );
        } 
        else if(this.selectedNode.nodeType == "objectizer" || this.selectedNode.nodeType == "dataStore"){
          if(this.selectedNode.configItemsByNames["variables"] && this.selectedNode.configItemsByNames["variables"]){
            this.recalcio(this.selectedNode.configItemsByNames["variables"].value.map(x=>`{{${x}}}`).join(" "), this.selectedNode, true)

          }
        }
        else if(this.selectedNode.nodeType != "entityWriter" && this.selectedNode.nodeType != "subTemplateNode" && this.selectedNode.nodeType != "redirect" && this.selectedNode.nodeType != "dynamicOutputNode") {
          let requiredField = null;
          if(this.nodeTypesById[this.selectedNode.nodeType]){
            requiredField = this.nodeTypesById[this.selectedNode.nodeType].inputs;
          }
          this.recalcio(this.getConfigAsString(newConfig), this.selectedNode, true, requiredField)
      
        }
      },
    },
  },
  created() {

   

    // Register module
    if (!store.hasModule(this.TEMPLATE_EDITOR_STORE_MODULE_NAME)) store.registerModule(this.TEMPLATE_EDITOR_STORE_MODULE_NAME, contiltEditorStoreModule)



    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  destroyed() {
    store
    .dispatch(`${this.TEMPLATE_EDITOR_STORE_MODULE_NAME}/destroy`)

    if (store.hasModule(this.TEMPLATE_EDITOR_STORE_MODULE_NAME)) store.unregisterModule(this.TEMPLATE_EDITOR_STORE_MODULE_NAME)
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
  mounted() {
    this.$nextTick(function () {
      let self = this;

 
      var elements = document.getElementsByClassName("drag-drawflow");
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener("touchend", this.drop, false);
        elements[i].addEventListener("touchmove", this.positionMobile, false);
        elements[i].addEventListener("touchstart", this.drag, false);
      }
     

    axios
      .get(`/template-nodes`)
      .then((response) => {
 
        this.nodeTypeList = response.data.nodeTypeList
   

        this.possibleCategoies = response.data.categories;
        this.categoryLabels = response.data.labels;
        if(this.templateTypesFromQuery && this.templateTypesFromQuery.length >0){
              const templateTypesFromQueryArray = this.templateTypesFromQuery.split(",").map(x=>x.trim()).filter(x=>x.length > 0)
              this.templateTypes = this.possibleTemplateTypes.filter(x => templateTypesFromQueryArray.indexOf(x.id) > -1)
        }

        let localNodes = null;
        if(this.nodesToPassIdFromQuery){
          localNodes = localStorage.getItem(this.nodesToPassIdFromQuery);
          if(localNodes){
            localNodes = JSON.parse(localNodes);
          }
          localStorage.removeItem(this.nodesToPassIdFromQuery)
        }
        
        if(this.debugId){
          axios.get(`debuglogs/${this.debugId}`).then((res) => {
              this.loadTemplateById(null, res.data.debugNodes.filter(x=>x.data.parentNodeId == this.parentNodeId).map(x=>x.data.node));

            
          })
          
        }else {
          this.loadTemplateById(this.templateId, localNodes);
        }
        
        
      })
      .catch((error) => console.log(error));

    });
  },
  data() {
    return {
      TEMPLATE_EDITOR_STORE_MODULE_NAME: 'app-template-editor',
      nodeTypeList: [],
      templateData: null,
      mobile_item_selec: "",
      workflowCategories: [],
      workflowLabels: [],
      mobile_last_move: null,
      selectedNode: null,
      selectedNodeId: null,
      selectedPathId: null,
      hoverConfig: false,
      pin: false,
      templateName: "",
      templateImageUrl: "",
      templateImageFile: null,
      templateDocumentation: "",
      applyFreeText: false,
     
      possibleCategoies: [],
      categoryLabels: null,
      templateTypes: [],
      order: 1,
      possibleTemplateTypes: [
        {
          id: "MAIN_TEMPLATE",
          value: "Main Template",
        },
        {
          id: "SUB_TEMPLATE",
          value: "Sub Template",
        },
        {
          id: "PUBLIC_SUB_TEMPLATE",
          value: "Public Sub Template",
        },
        {
          id: "FOR_TEMPLATE",
          value: "For Template",
        },
        {
          id: "PUBLIC_FOR_TEMPLATE",
          value: "Public For Template",
        },
        {
          id: "ENTITY",
          value: "Entity",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.drawflow .content {
  margin: auto !important;
}
.contilt-df-wrapper .parent-drawflow {
  display: flex;
  overflow: hidden;
  touch-action: none;
  outline: none;
}

.contilt-df-wrapper .drawflow {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  perspective: 0;

}

.contilt-df-wrapper .drawflow .parent-node {
  position: relative;
}

.contilt-df-wrapper .drawflow .drawflow-node {
  display: flex;
  align-items: center;
  position: absolute;
  width: 160px;
  border: transparent;
  z-index: 2;
  padding: 0;
}

.contilt-df-wrapper .drawflow .drawflow-node.For, .contilt-df-wrapper .drawflow .drawflow-node.Sub, .contilt-df-wrapper .drawflow .drawflow-node.Entity .contilt-df-wrapper .drawflow .drawflow-node.Visual {

  width: 340px !important;
 
}


.contilt-df-wrapper .df-wrapper {
  width: 80%;
  float: left;
  margin: 0px;
}
.contilt-df-wrapper .drawflow svg.connection {
  z-index: 0;
  position: absolute;
  overflow: visible !important;
}
.contilt-df-wrapper .df-right-editor {
  margin: 0px;
  width: 20%;
}

.contilt-df-wrapper .drawflow-node {
  width: 200px !important;
}

.contilt-df-wrapper .drawflow-node.has-multi-put {
  width: 285px !important;
}

.contilt-df-wrapper .drawflow .drawflow-node.selected {
  background: #d1f1ff82;
}
.contilt-df-wrapper .drawflow .drawflow-node.error {
  background: #c1000061
}

.contilt-df-wrapper .drawflow .drawflow-node:hover {
  cursor: move;
}

.contilt-df-wrapper .drawflow .drawflow-node .inputs,
.contilt-df-wrapper .drawflow .drawflow-node .outputs {
  width: 0px;
  z-index: -6;
}

.contilt-df-wrapper .drawflow .drawflow-node .drawflow_content_node {
  width: 100%;
  display: block;
}

.contilt-df-wrapper .drawflow-node .port-text {
  position: absolute;
  left: 20px;
  top: 1.5px;
  font-size: 9px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #666666;
}

.contilt-df-wrapper .outputs .port-text {
  left: initial;
  right: 20px;
  width: 200px;
  text-align: right;
}

.contilt-df-wrapper
  .drawflow
  .drawflow-node.has-multi-put
  .drawflow_content_node
  .title-box,
.contilt-df-wrapper
  .drawflow
  .drawflow-node.has-multi-put
  .drawflow_content_node
  .box {
  width: 60%;
  padding-left: 21%;
  padding-right: 19%;
}

.contilt-df-wrapper .drawflow .drawflow-node .input,
.contilt-df-wrapper .drawflow .drawflow-node .output {
  position: relative;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  border: 2px solid black;
  cursor: crosshair;
  z-index: 1;
  margin-bottom: 5px;
}

.contilt-df-wrapper .drawflow .drawflow-node .input {
  left: -27px;
  top: 2px;
  background: yellow;
}

.contilt-df-wrapper .drawflow .drawflow-node .output {
  right: -3px;
  top: 2px;
}

.contilt-df-wrapper .drawflow .connection {
  position: absolute;
  pointer-events: none;
  aspect-ratio: 1 / 1;
}

.contilt-df-wrapper .drawflow .connection .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: steelblue;
  pointer-events: all;
}

.contilt-df-wrapper .drawflow .connection .main-path:hover {
  stroke: #1266ab;
  stroke-width: 7px;
  cursor: pointer;
}

.contilt-df-wrapper .drawflow .connection .main-path.selected {
  stroke: #43b993;
}

.contilt-df-wrapper .drawflow .connection .point {
  cursor: move;
  stroke: black;
  stroke-width: 2;
  fill: white;
  pointer-events: all;
}

.contilt-df-wrapper .drawflow .connection .point.selected,
.contilt-df-wrapper .drawflow .connection .point:hover {
  fill: #1266ab;
}

.contilt-df-wrapper .drawflow .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: steelblue;
}

.contilt-easetans {
  transition: all 0.3s ease;
}

.contilt-df-wrapper .drawflow-delete,
.contilt-df-wrapper .drawflow-clone {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background: black;
  color: white;
  z-index: 4;
  border: 2px solid white;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  font-family: monospace;
  cursor: pointer;
}

.contilt-df-wrapper .drawflow > .drawflow-delete {
  margin-left: -15px;
  margin-top: 15px;
}
.contilt-df-wrapper .drawflow > .drawflow-clone {
  margin-left: -15px;
  margin-top: 30px;
}

.contilt-df-wrapper .parent-node .drawflow-delete {
  right: -15px;
  top: -15px;
}

.contilt-df-wrapper .parent-node .drawflow-clone {
  right: -15px;
  top: 15px;
}
:root {
  --border-color: #cacaca;
  --background-color: #f1f1f1;
  --background-box-title: #eeeeee;
}

/*html,
body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}
*/
.contilt-df-wrapper header {
  height: 66px;
  padding-left: 20px;
}

.contilt-df-wrapper header h2 {
  margin: 0px;
  line-height: 66px;
}

.contilt-df-wrapper header a {
  color: black;
}

.contilt-df-wrapper .them-edit-link {
  position: absolute;
  top: 10px;
  right: 100px;
  color: black;
  font-size: 40px;
}

.contilt-df-wrapper .them-edit-link a {
  text-decoration: none;
}

.contilt-df-wrapper .github-link {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
}

.contilt-df-wrapper .wrapper {
  width: 100%;
  height: calc(89vh);
  display: flex;
}

.contilt-df-wrapper .df-right-editor {
  width: 20%;
  padding-top: 50px;
  height: calc(89vh);
  display: flex;
}

.contilt-df-wrapper .col {
  overflow: auto;
  width: 250px;
  height: 100%;
  border-right: 1px solid var(--border-color);
}

.contilt-df-wrapper .vue-form-wizard .wizard-tab-content {
  padding: 0px 0px 0px;
}

.contilt-df-wrapper .drag-drawflow {
  cursor: move;
  user-select: none;
}

.contilt-df-wrapper .menu {
  position: absolute;
  height: 40px;
  display: block;
  background: white;
  width: 100%;
}

.contilt-df-wrapper .menu ul {
  padding: 0px;
  margin: 0px;
  line-height: 40px;
}

.contilt-df-wrapper .menu ul li {
  display: inline-block;
  margin-left: 10px;
  border-right: 1px solid var(--border-color);
  padding-right: 10px;
  line-height: 40px;
  cursor: pointer;
}

.contilt-df-wrapper .menu ul li.selected {
  font-weight: bold;
}

.contilt-df-wrapper .btn-export {
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-weight: bold;
  border: 1px solid #0e5ba3;
  background: #4ea9ff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 5;
}

.contilt-df-wrapper .btn-clear {
  float: right;
  position: absolute;
  top: 10px;
  right: 85px;


  z-index: 5;
}

.contilt-df-wrapper .swal-wide {
  width: 80% !important;
}

.contilt-df-wrapper .btn-lock {
  float: right;
  position: absolute;
  bottom: 10px;
  right: 140px;
  display: flex;
  font-size: 24px;
  color: white;
  padding: 5px 10px;
  background: #555555;
  border-radius: 4px;
  border-right: 1px solid var(--border-color);
  z-index: 5;
  cursor: pointer;
}

.contilt-df-wrapper .bar-zoom {
  float: right;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  font-size: 24px;
  color: white;
  padding: 5px 10px;
  background: #555555;
  border-radius: 4px;
  border-right: 1px solid var(--border-color);
  z-index: 5;
}

.contilt-df-wrapper .bar-zoom svg {
  cursor: pointer;
  padding-left: 10px;
}

.contilt-df-wrapper .bar-zoom svg:nth-child(1) {
  padding-left: 0px;
}

.contilt-df-wrapper #drawflow {
  position: relative;
  width: 100%;
  height: calc(100%);
  top: 0px;
  background: var(--background-color);
  background-size: 25px 25px;
  background-image: linear-gradient(to right, #f1f1f1 1px, transparent 1px),
    linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
}

@media only screen and (max-width: 768px) {
  .contilt-df-wrapper .col {
    width: 50px;
  }

  .contilt-df-wrapper .col .drag-drawflow span {
    display: none;
  }

  .contilt-df-wrapper #drawflow {
    width: calc(100vw - 51px);
  }
}

/* Editing Drawflow */
.contilt-df-wrapper .drawflow .drawflow-node {
  background: white;
  border: transparent;
  padding: 0px;
  width: 200px;
    border-radius: 8px;
}


.contilt-df-wrapper .drawflow .drawflow-node.selected {
  border: transparent;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 20px 2px rgba(78, 169, 255, 0.3);
  box-shadow: 0 2px 20px 2px rgba(78, 169, 255, 0.3);
}

.contilt-df-wrapper .drawflow .drawflow-node .card {
  margin-bottom: 0px;
}

.contilt-df-wrapper .drawflow .drawflow-node.selected .title-box {
  color: #22598c;
  /*border-bottom: 1px solid #4ea9ff;*/
}

.contilt-df-wrapper .drawflow .connection .main-path {
  stroke: #4ea9ff;
  stroke-width: 3px;
}

.contilt-df-wrapper .drawflow .drawflow-node .input,
.contilt-df-wrapper .drawflow .drawflow-node .output {
  height: 15px;
  width: 15px;
  border: 2px solid var(--border-color);
}

.contilt-df-wrapper .drawflow .drawflow-node .input:hover,
.contilt-df-wrapper .drawflow .drawflow-node .output:hover {
  background: #4ea9ff;
}

.contilt-df-wrapper .drawflow .drawflow-node .output {
  right: 5px;
}

.contilt-df-wrapper .drawflow .drawflow-node .input {
  left: -10px;
  background: white;
}

.contilt-df-wrapper .drawflow > .drawflow-delete,
.contilt-df-wrapper .drawflow > .drawflow-clone {
  border: 2px solid #43b993;
  background: white;
  color: #43b993;
  -webkit-box-shadow: 0 2px 20px 2px #43b993;
  box-shadow: 0 2px 20px 2px #43b993;
}

.contilt-df-wrapper .drawflow-delete,
.contilt-df-wrapper .drawflow-clone {
  border: 2px solid #4ea9ff;
  background: white;
  color: #4ea9ff;
  -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
  box-shadow: 0 2px 20px 2px #4ea9ff;
}

.contilt-df-wrapper .drawflow-node .title-box {
  height: 50px;
  line-height: 50px;
  background: var(--background-box-title);
  border-bottom: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  padding-left: 10px;
}

.contilt-df-wrapper .drawflow .title-box svg {
  position: initial;
}

.contilt-df-wrapper .drawflow-node .box {
  padding: 10px 20px 20px 20px;
  font-size: 14px;
  color: #555555;
}

.contilt-df-wrapper .drawflow-node .box p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.contilt-df-wrapper .drawflow-node.welcome {
  width: 250px;
}

.contilt-df-wrapper .drawflow-node.slack .title-box {
  border-radius: 4px;
}




.contilt-df-wrapper .drawflow-node.personalized {
  background: red;
  height: 200px;
  text-align: center;
  color: white;
}

.contilt-df-wrapper .drawflow-node.personalized .input {
  background: yellow;
}

.contilt-df-wrapper .drawflow-node.personalized .output {
  background: green;
}

.contilt-df-wrapper .drawflow-node.personalized.selected {
  background: blue;
}

.contilt-df-wrapper .drawflow .connection .point {
  stroke: var(--border-color);
  stroke-width: 2;
  fill: white;
}

.contilt-df-wrapper .drawflow .connection .point.selected,
.contilt-df-wrapper .drawflow .connection .point:hover {
  fill: #4ea9ff;
}

/* Modal */
.contilt-df-wrapper .modal {
  display: none;
  position: fixed;
  z-index: 7;
  left: 0;
  top: 0;
  width: 100vw;
  height: 89vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
}

.contilt-df-wrapper .modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 400px;
  /* Could be more or less, depending on screen size */
}
.category-header {
      text-align: left;
  margin: 0.8rem 0 0.9rem 0.6rem;
}
.category-header span {
  font-weight: 500;
    color: #a6a4b0;
    line-height: 1.5;
    letter-spacing: .01rem;
    font-size: .9rem;
    text-transform: uppercase;
}
/* The Close Button */
.contilt-df-wrapper .modal .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .contilt-df-wrapper .modal-content {
    width: 100%;
  }
}

.bar-zoom {
  float: right;
  position: absolute;
  bottom: 8px;
  right: 16px !important;
  display: flex;
  font-size: 24px;
  color: white;
  padding: 2px 2px;
  padding-left: -2px;
  padding-right: -2px;
  background: #555555;
  border-radius: 4px;
  border-right: 1px solid var(--border-color);
  z-index: 5;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
  margin: 4px;
  margin-left: 7px;
  margin-right: 7px;
}


.sub-templates-contianer {
  height: 230px;
  margin-top: 20px;
}
.sub-templates-contianer .sub-templates-contianer-list {
  height: 100%;
  overflow: scroll;
  margin-top: 20px;
}
.sub-templates-contianer h3 {
  font-size: 15px;
  text-align: left;
}
.s-noselect { 
    -webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;
}

#s-rectBox { 
    position: absolute;
    z-index: 1090;
    border:2px dashed #cbd3e3; 
}

 .wizard-nav-pills{
  overflow: scroll;
   overflow-x: hidden;
   height: calc(89vh);
}
.contilt-df-wrapper .vue-form-wizard .wizard-nav-pills {
  flex-wrap: initial !important;
}

.contilt-df-wrapper .wizard-nav {
  min-width: 255px !important;
}

.template-name-header {
    position: fixed;
    z-index: 15;
    top: 37px;
    left: 130px;
    font-weight: bold;
    
}
.organization-name {
  font-size: 1.4em;
  color: #59b0d7;
}
.template-name {
  font-size: 1.3em;
  color:  #9ccbe1;
}
.component-title {
  max-width: 130px !important;
}
</style>