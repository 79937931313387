<template>
  <div class="parent-node">
    <div
      :id="'node-' + node.id"
      :class="nodeMainClass"
      :style="{ top: node.pos_y + 'px', left: node.pos_x + 'px' }"
    >
      <div class="inputs">
        <div
          class="input"
          :class="input"
          :input="input"
          :key="index"
          v-for="(input, index) in node.in_ports"
        >
          <div
            class="port-text"
            v-if="node.in_ports.length > 1 || node.visibleText"
          >
            {{ input }}
          </div>
        </div>
      </div>
      <div class="drawflow_content_node">
        <b-card class="text-center" :bg-variant="node.backgroundcolor">
          <!-- <b-avatar
            class="mb-1"
            :variant="`light-${node.iconbordercolor || 'primary'}`"
            size="42"
            v-if="node.icon"
          >
            <feather-icon size="21" :icon="node.icon" />
          </b-avatar> -->
          <div class="truncate">
            <b-card-title class="text-grey" style="margin-bottom: 0px">
              {{ currentName }}
            </b-card-title>
            <b-card-text v-if="currentDescription">
              {{ currentDescription }}
            </b-card-text>
          </div>
          <div
            v-if="
              (node.nodeType == 'forNode' || node.nodeType == 'entityWriter' || node.nodeType == 'entityReader'||
                node.nodeType == 'subTemplateNode' || node.nodeType == 'dynamicOutputNode'|| node.nodeType == 'redirect' || node.nodeType == 'visualTemplate') &&
              node.configItemsByNames.templateId
            "
          >
            <v-select
              label="text"
              @input="
                setSelected($event, node.configItemsByNames.templateId.options)
              "
              @open="onSelectOpen(node, node.configItemsByNames.templateId)"
              :reduce="(option) => option.value"
              v-model="node.configItemsByNames.templateId.value"
              :options="node.configItemsByNames.templateId.options"
            />
            <div class="demo-inline-spacing">
              <b-button
              v-if="node.nodeType == 'subTemplateNode' && node.debugData && node.debugData.error"
                @click="viewError(node.configItemsByNames.templateId.value)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                >View Error</b-button
              >
              <b-button
                @click="
                  $emit(
                    'open-new-sub-template',
                    node.nodeType == 'forNode' ? 'FOR_TEMPLATE' : 'SUB_TEMPLATE'
                  )
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                >New</b-button
              >
              <b-button
                @click="
                  $emit(
                    'open-new-tab-template',
                    node.configItemsByNames.templateId.value
                  )
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="secondary"
                v-if="node.configItemsByNames.templateId.value"
                >Edit</b-button
              >
              <b-button
                @click="$emit('connect-to-inputs')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="success"
       
                >Connect</b-button>
            </div>
          </div>
        </b-card>
      </div>
      <div class="outputs">
        <div
          class="output"
          :class="out"
          :output="out"
          :key="node.id + '-' + index"
          v-for="(out, index) in node.out_ports"
        >
          <div
            class="port-text"
            v-if="node.out_ports.length > 1 || node.visibleText"
          >
            {{ out }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BCard, BAvatar, BCardText, BCardTitle, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BAvatar,
    BCardText,
    BCardTitle,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: ["node", "templateId", "selectedNodeId"],
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$nextTick(function () {
      this.$emit("on-created", this.node.id);
      this.onSelectOpen(this.node, this.node.configItemsByNames.templateId)
    });
  },
  methods: {
    viewError(templateId){
      const routeData = this.$router.resolve({ 
        name: 'template-to-edit', 
        params:{
          templateId: templateId
        }, 
        query: {...this.$route.query, parentNodeId: this.node.id}
      });
      window.open(routeData.href, '_blank');

    },
    setSelected(value, options) {
      if (!value) {
        this.$emit("sub-template-data", [], []);
        return;
      }
     
      const option = options.find((x) => x.value == value);
      if(option && this.node.nodeType == "redirect"){
        this.$emit("sub-template-data", option.inputs, []);
        return;
      }
      if (option && option.inputs && option.outputs) {
        this.$emit("sub-template-data", option.inputs, option.outputs);
      }
      else if(option && option.inputs){
        this.$emit("sub-template-data", option.inputs, []);
      }
      else if(option && option.outputs){
        this.$emit("sub-template-data", [], option.outputs);
      }
    },
    onSelectOpen(node, configItem) {
      if(!configItem){
        return;
      }
      if (configItem.loading) {
        if (configItem.loading == "getForTemplates") {
          axios
            .get(`/templates`, {
              params: {
                templateTypes: "PUBLIC_FOR_TEMPLATE",
                selectedFields: "id name",
                limit: 100,
                onlyTemplates: 1,
                organizations: this.organization,
              },
            })
            .then((res) => {
              const dd = res.data.templates.results.map((x) => {
                return {
                  text: x.name,
                  value: x.id,
                };
              });
              if(this.templateId){
                axios
                  .get(`/templates`, {
                    params: {
                      sourceReference: this.templateId,
                      templateTypes: "FOR_TEMPLATE,PUBLIC_FOR_TEMPLATE",
                      selectedFields: "id name",
                      limit: 100,
                      onlyTemplates: 1,
                      organizations: this.organization,
                    },
                  })
                  .then((res) => {
                      const ddSet = new Set(dd.map(x=>x.value));
                      const newDD = res.data.templates.results.map(x => {
                          return {
                              text: x.name,
                              value: x.id,
                          };
                      }).filter(x=> !ddSet.has(x.value));
                      configItem.options = dd.concat(newDD);
                  });
              }else {
                configItem.options = dd;
              }

            });
        }
        if (configItem.loading == "getEntities") {
          axios
            .get(`/entities`, {
              params: {
                selectedFields: "id name fields",
                limit: 100,
                organizations: this.organization,
              },
            })
            .then((res) => {
              configItem.options = res.data.results.map((x) => {
                return {
                  text: x.name,
                  value: x.id,
                  inputs: x.fields.map(y=>{return {name: y}})
                };
              });
            });
        }
        if (configItem.loading == "getVisualTemplates") {
          axios
            .get(`/visualTemplates`, {
              params: {
                selectedFields: "id name templateTypeId",
                populate: "templateTypeId$fields",
                limit: 100,
                organizations: this.organization,
              },
            })
            .then((res) => {
              configItem.options = res.data.results.map((x) => {
                return {
                  text: x.name,
                  value: x.id,
                  inputs: x.templateTypeId.fields.map(y=>{return {name: y.name}}),
                };
              });
            });
        }

        if (configItem.loading == "getEntityKeys") {
          axios
            .get(`/entity-keys`, {
              params: {
                selectedFields: "id name",
                populate: "entityId$name$id$fields",
                limit: 100,
                organizations: this.organization,
              },
            })
            .then((res) => {
              configItem.options = res.data.results.map((x) => {
                return {
                  text: x.name,
                  value: x.id,
                  inputs: node.nodeType == "entityWriter" ? x.entityId.fields.map(y=>{return {name: y}}): null,
                  outputs: node.nodeType == "entityReader"? x.entityId.fields.map(y=>{return {name: y}}) : null
                };
              });
            });
        }
        if (configItem.loading == "getSubTemplates") {
          let privateSubTemplatePromise = new Promise((reslove) => reslove());
          if (this.templateId) {
            privateSubTemplatePromise = new Promise((reslove, reject) => {
              axios
                .get(`/templates`, {
                  params: {
                    sourceReference: this.templateId,
                    templateTypes: "SUB_TEMPLATE",
                    selectedFields: "id name inputs outputs",
                    limit: 100,
                    onlyTemplates: 1,
                    organizations: this.organization,
                  },
                })
                .then((res) => {
                  reslove(
                    res.data.templates.results.map((x) => {
                      return {
                        text: x.name,
                        value: x.id,
                        inputs: x.inputs,
                        outputs: node.nodeType == "dynamicOutputNode" ? [] : x.outputs,
                      };
                    })
                  );
                })
                .catch((e) => reject(e));
            });
          }
          privateSubTemplatePromise.then((res) => {
            let newOptions = [];
            if (res) {
              newOptions = res;
            }
            axios
              .get(`/templates`, {
                params: {
                  templateTypes: "PUBLIC_SUB_TEMPLATE",
                  selectedFields: "id name inputs outputs",
                  limit: 100,
                  onlyTemplates: 1,
                  organizations: this.organization,
                },
              })
              .then((res) => {
                const idsSet = new Set(newOptions.map(x=>x.value))
                newOptions = newOptions.concat(
                  res.data.templates.results.filter(x => !idsSet.has(x.id)).map((x) => {
                    return {
                      text: x.name,
                      value: x.id,
                      inputs: x.inputs,
                      outputs: node.nodeType == "dynamicOutputNode" ? [] : x.outputs,
                    };
                  })
                );
                configItem.options = newOptions;
              });
          });
        }
      }
    },
  },
  computed: {
    organization() {
      return this.$route.query.organization;
    },
    nodeMainClass() {
      let multiClass =
        this.node.in_ports.length > 1 ||
        this.node.out_ports.length > 1 ||
        this.node.visibleText
          ? " has-multi-put"
          : "";
      let isError = this.node.debugData && this.node.debugData.error ? " error" : "";
      const isSelected = this.selectedNodeId == this.node.id ? " selected" : "";
      let cls = `drawflow-node ${this.node.name}${multiClass}${isError}${isSelected}`;
      return cls;
    },
    currentName() {
      if (
        (this.node.nodeType == "inputNode" ||
          this.node.nodeType == "outputNode") &&
        this.node.configItems[0].value.length > 0
      ) {
        return this.node.configItems[0].value;
      } else if (
        this.node.configItemsByNames.label &&
        this.node.configItemsByNames.label.value.length > 0
      ) {
        return this.node.configItemsByNames.label.value;
      }
      return this.node.name;
    },
    currentDescription() {
      if (
        (this.node.nodeType == "inputNode" ||
          this.node.nodeType == "outputNode") &&
        this.node.configItems[0].value.length > 0
      ) {
        return this.node.name;
      } else if (
        this.node.configItemsByNames.label &&
        this.node.configItemsByNames.label.value.length > 0
      ) {
        return this.node.name;
      }
      this.node.description;
    },
  },
};
</script>
<style scoped>
.drawflow_content_node .card-body {
  width: 87% !important;
  margin: auto !important;
}
.drawflow_content_node .card {
  box-shadow: initial !important;
  background-color: transparent !important;
}
</style>