<template>
  <div>
    <div class="debug-container" :key="index" v-for="(item, index) in values">
      <label>{{ item.name }}</label>
      <div class="debug-value">
        {{item.value}}
      </div>
    </div>
  </div>
</template>

<script>
function getType(p) {
    if (Array.isArray(p)) return 'list';
    else if (typeof p == 'string') return 'string';
    else if (p != null && typeof p == 'object') return 'object';
    else return 'other';
}

function strRec(obj, conversionConfig, exclude){
    let str = "";
    let type = getType(obj);
    if(type == "string"){
        return obj;
    }else if(type == "list"){
        obj.forEach(x => {
            str += strRec(x, conversionConfig, exclude) + conversionConfig.list.seperator;
           
        })
        str = str.substring(0, str.lastIndexOf(conversionConfig.list.seperator));
    }else if(type == "object") {
        Object.keys(obj).forEach(x => {
            if(!exclude.has(x) && obj[x]){
                str += x + conversionConfig.object.keyValueSeperator + strRec(obj[x], conversionConfig, exclude) + conversionConfig.object.seperator
            }
        })
    }
  
    return str.trim();
}

export default {
  props: ["node", "targetContext"],
  data(){   
    return {
        values: null,
        conversionConfig: {
        list: {
            seperator: ", "
        },
        object: {
            seperator: "\n",
            includeKey: true,
            keyValueSeperator: ": "
        },
        string: {
            
        }
      }
    }
    
  },
  mounted() {
    this.$nextTick(() => {
        this.values = this.getValues();
    });
  },
  methods: {
    convert(element, exclude){
        return strRec(element, this.conversionConfig, exclude);
    },

    getContext(targetContext, onlyFirst = false) {
      const context = {};
      Object.keys(targetContext).forEach((n) => {
        if (
          !targetContext[n].connections.length ||
          !targetContext[n].connections[0].value
        ) {
          context[n] = "";
          return;
        }

        const type = getType(targetContext[n].connections[0].value);
        if (type == "list") {
          context[n] = [];
          targetContext[n].connections.forEach((x, i) => {
            if(onlyFirst && i>=1){
                return
            }
            if (x.value) {
              const valueType = getType(x.value);
              if (valueType == "list") {
                context[n] = context[n].concat(x.value);
              } else if (valueType == "string") {
                context[n].push(x.value);
              }
            }
          });
        } else if (type == "string") {
          context[n] = "";
          targetContext[n].connections.forEach((x, i) => {
            if(onlyFirst && i>=1){
                return
            }
            if (x.value) {
              context[n] = context[n] + this.convert(x.value, null);
            }
          });
        } else {
          context[n] = targetContext[n].connections[0].value;
        }
      });
      return context;
    },

    getValues() {
      let res = [];
      let _this = this;
      if(this.targetContext == "inputs"){
        const inputContext = this.getContext(this.node.inputs);
        this.node.in_ports.forEach((element) => {
            const el = { name: element };
            if (inputContext[element] ) {
                el.value = inputContext[element] 
            }
            res.push(el);
        });
        return res;
      }else {
        const outputContext = this.getContext(this.node.outputs, true);
        this.node.out_ports.forEach((element) => {
            const el = { name: element };
            if (outputContext[element] ) {
                el.value = outputContext[element] 
            }
            res.push(el);
        });
        return res;
      }
     
    },
  },
};
</script>

<style>
    .debug-value {
            box-shadow: 0 2px 20px 2px rgb(137 137 137 / 30%);
    padding: 20px;
    outline: none;
    border-radius: 5px;
    }
    .debug-container {
        margin: 10px;
    }
</style>