var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"parent-node"},[_c('div',{class:_vm.nodeMainClass,style:({ top: _vm.node.pos_y + 'px', left: _vm.node.pos_x + 'px' }),attrs:{"id":'node-' + _vm.node.id}},[_c('div',{staticClass:"inputs"},_vm._l((_vm.node.in_ports),function(input,index){return _c('div',{key:index,staticClass:"input",class:input,attrs:{"input":input}},[(_vm.node.in_ports.length > 1 || _vm.node.visibleText)?_c('div',{staticClass:"port-text"},[_vm._v(" "+_vm._s(input)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"drawflow_content_node"},[_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":_vm.node.backgroundcolor}},[_c('div',{staticClass:"truncate"},[_c('b-card-title',{staticClass:"text-grey",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(_vm.currentName)+" ")]),(_vm.currentDescription)?_c('b-card-text',[_vm._v(" "+_vm._s(_vm.currentDescription)+" ")]):_vm._e()],1),(
            (_vm.node.nodeType == 'forNode' || _vm.node.nodeType == 'entityWriter' || _vm.node.nodeType == 'entityReader'||
              _vm.node.nodeType == 'subTemplateNode' || _vm.node.nodeType == 'dynamicOutputNode'|| _vm.node.nodeType == 'redirect' || _vm.node.nodeType == 'visualTemplate') &&
            _vm.node.configItemsByNames.templateId
          )?_c('div',[_c('v-select',{attrs:{"label":"text","reduce":function (option) { return option.value; },"options":_vm.node.configItemsByNames.templateId.options},on:{"input":function($event){return _vm.setSelected($event, _vm.node.configItemsByNames.templateId.options)},"open":function($event){return _vm.onSelectOpen(_vm.node, _vm.node.configItemsByNames.templateId)}},model:{value:(_vm.node.configItemsByNames.templateId.value),callback:function ($$v) {_vm.$set(_vm.node.configItemsByNames.templateId, "value", $$v)},expression:"node.configItemsByNames.templateId.value"}}),_c('div',{staticClass:"demo-inline-spacing"},[(_vm.node.nodeType == 'subTemplateNode' && _vm.node.debugData && _vm.node.debugData.error)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.viewError(_vm.node.configItemsByNames.templateId.value)}}},[_vm._v("View Error")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$emit(
                  'open-new-sub-template',
                  _vm.node.nodeType == 'forNode' ? 'FOR_TEMPLATE' : 'SUB_TEMPLATE'
                )}}},[_vm._v("New")]),(_vm.node.configItemsByNames.templateId.value)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.$emit(
                  'open-new-tab-template',
                  _vm.node.configItemsByNames.templateId.value
                )}}},[_vm._v("Edit")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.$emit('connect-to-inputs')}}},[_vm._v("Connect")])],1)],1):_vm._e()])],1),_c('div',{staticClass:"outputs"},_vm._l((_vm.node.out_ports),function(out,index){return _c('div',{key:_vm.node.id + '-' + index,staticClass:"output",class:out,attrs:{"output":out}},[(_vm.node.out_ports.length > 1 || _vm.node.visibleText)?_c('div',{staticClass:"port-text"},[_vm._v(" "+_vm._s(out)+" ")]):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }