<template>
    <div>
        <div class="container">
            <label>Error Message</label>
            <div class="message">
                {{error.message}}
            </div>
        </div>
        <div class="container">
            <label>Error Stack</label>
            <div class="message" v-html="stack">
          
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["error"],
    computed: {
        stack(){
            if(!this.error.stack){
                return ""
            }
            return this.error.stack.replace(/[\n]+/g, "<br>")
        }
    }
}
</script>
<style scoped>
    .message {
            box-shadow: 0 2px 20px 2px rgb(137 137 137 / 30%);
    padding: 20px;
    outline: none;
    border-radius: 5px;
    }
    .container {

        margin-top: 15px;
    }
</style>