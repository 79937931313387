import { ContiltEditor } from './contilt-editor'
export default {
  namespaced: true,
  state: {
    contiltEditor: null,
  },
  getters: {
    editor(state){
      return state.contiltEditor ? state.contiltEditor.getEditor() : null;
    },
    nodes(state){
      return state.contiltEditor ? state.contiltEditor.getNodes(): [];
    },
    nodeTypeList(state){
      return state.contiltEditor ? state.contiltEditor.getNodeTypeList(): [];
    },
    nodeTypeById(state){
      return state.contiltEditor ? state.contiltEditor.getNodeTypeById(): {};
    },
    selectedNodeId(state){
      return state.contiltEditor ? state.contiltEditor.getSelectedNodeId(): null;
    },
    getOutputsStructure: (state) => () => {
      return state.contiltEditor ? state.contiltEditor.getOutputsStructure(): null;
    },
    getDynamicOutputsStructure: (state) => () => {
      return state.contiltEditor ? state.contiltEditor.getDynamicOutputsStructure(): null;
    },
    getInputsStructure: (state) => () => {
      return state.contiltEditor ? state.contiltEditor.getInputsStructure(): null;
    }
  },
  mutations: {
    init(state, {elementId, nodeTypeList, callbacks}){
      state.contiltEditor = new ContiltEditor(elementId, nodeTypeList, callbacks);

    },
    destroy(state){

      state.contiltEditor.destroy()

    },
    import(state, nodes){
      state.contiltEditor.importNodes(nodes)
    },
    addNodeByNodeTypeName(state, {name, pos_x, pos_y}) {
      state.contiltEditor.addNodeByNodeTypeName(name, pos_x, pos_y);
    },
    connectNodeToInputs(state, {nodeId, inputNodes}) {
      state.contiltEditor.connectNodeToInputs(nodeId, inputNodes);
    }

  },
  actions: {
    init(context, {elementId, nodeTypeList, callbacks}){
      context.commit("init", {elementId, nodeTypeList, callbacks})
    },
    destroy(context){
      context.commit("destroy")
    },
    import(context, nodes){
      context.commit("import", nodes)
    },
    addNodeByNodeTypeName(context, {name, pos_x, pos_y}){
      context.commit("addNodeByNodeTypeName", {name, pos_x, pos_y})
    },
    connectNodeToInputs(context, {nodeId, inputNodes}){
      context.commit("connectNodeToInputs", {nodeId, inputNodes})
    }
 
  },
}
